import React from 'react';
import Contact from "../Contact";
import {Link} from "react-router-dom";

const OmOss = () => (
  <div className="row content-row page-content">
    <div className="col-sm-9">
      <div className="blog-post">
        <h1 className="blog-post-title">Om oss</h1>
        <p>Etter 20 års fartstid som skytebas bestemte Leif Andreas Ludviksen seg for å starte et foretak for salg og utleie av utstyr for anleggsbransjen. Vårt mål er å levere best utstyr til best pris, samt å hevde oss på service og kunnskap om produktene vi selger.</p>
        <p><Link to="/produkter/" className="btnlink">SE VÅRE PRODUKTER</Link></p>
      </div>
    </div>
    <div className="col-sm-3">
      <Contact />
    </div>
  </div>
);

export default OmOss;
