import React from 'react';
import boreutstyr from '../assets/boreutstyr.png';
import borekroner from '../assets/borekroner.png';
import skytematter from '../assets/skytematter.png';

const Hjem = () => (
    <div>
        <div className="frontpage-boxes">
            <div>
                <img src={boreutstyr}/>
                <h3>BOREUTSTYR</h3>
                <p>LA Consulting AS kan levere borehullsplugger, slipemaskiner og slipekopper, sirener og
                    mer.</p>
            </div>
            <div>
                <img src={skytematter}/>
                <h3>SKYTEMATTER</h3>
                <p>LA Consulting AS leverer skytematter fra Bergma av høy kvalitet. Vi kan levere ordinære
                    skytematter,
                    samt monstermatter med ekstra forsterkning.</p>
            </div>
            <div>
                <img src={borekroner}/>
                <h3>BOREKRONER</h3>
                <p>LA Consulting AS leverer borekroner av meget høy kvalitet. LA-kronen er en egenutviklet
                    borekrone med
                    spesielt gode styreegenskaper.</p>
            </div>
        </div>
    </div>
);

export default Hjem;
