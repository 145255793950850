import React from 'react';
import Contact from "../Contact";
import {Link} from "react-router-dom";

const Borekroner = () => (
  <div className="row content-row page-content">
    <div className="col-sm-9">
      <div className="blog-post">
          <h1 className="blog-post-title">Borekroner</h1>
          <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                  <p>LA Consulting AS leverer borekroner av meget høy kvalitet, produsert i Kina. Borstålet er levert av
                      Mitsubishi og kommer i dimensjoner fra R32 til T51. Borekronene har dype og kraftige hylser som
                      forlenger levetiden.</p>
                  <h2>LA-kronen</h2>
                  <p>LA-kronen ble introdusert på markedet i 2012. Tilbakemeldingene har vært gjennomgående positive.
                      Utformingen av denne kronen gir god borsynk i tillegg til at den er svært retningsstabil takket
                      være det unike styreskjørtet som dramatisk forbedrer styreegenskapene.</p>
                  <p>Vi leverer LA-kronen i følgende dimensjoner:</p>
                  <ul>
                      <li>Diameter 45 mm</li>
                      <li>Diameter 51 mm</li>
                      <li>Diameter 64 mm</li>
                      <li>Diameter 67 mm</li>
                      <li>Diameter 70 mm</li>
                      <li>Diameter 76 mm</li>
                      <li>Diameter 89 mm</li>
                      <li>Diameter 102 mm</li>
                      <li>Diameter 110 mm</li>
                  </ul>
                  <p>Utover standardmodeller kan vi tilby skreddersydde løsninger tilpasset dine behov. Noe lengre
                      leveringstid må påberegnes. Vi selger også tilpassede kasser for oppbevaring av kroner.</p>
                  <p>Bedriften Rock Partner AS ved Rune Ingebrigtsen har drevet utstrakt testing av disse kronene i Fana
                      Stein AS med til dels svært utfordrende bergarter. Ettersom resultatene har vært såpass gode, har
                      vi blitt totalleverandør av borekroner for dem.</p>
                  <p>Vassbakk &amp; Stol AS har på prosjektet Flesland Terminal brukt våre 70mm LA-kroner med godt
                      resultat. Dette viser hvor god kvalitet det er på disse kronene.</p>
                  <h2>Robit-kroner</h2>
                  <p>Vi leverer Robit-kroner i følgende dimensjoner:</p>
                  <ul>
                      <li>R28 / 38 mm</li>
                      <li>R28 / 41 mm</li>
                      <li>R32 / 41 mm</li>
                      <li>R32 / 45 mm</li>
                      <li>R32 / 51 mm</li>
                      <li>R32 / 51 mm Retrac DC</li>
                      <li>T38 / 54 mm</li>
                      <li>T38 / 57 mm</li>
                      <li>T38 / 64 mm Retrac DC</li>
                      <li>T38 / 70 mm Retrac DC</li>
                      <li>T38 / 76 mm Retrac DC</li>
                      <li>T45 / 70 mm Retrac DC</li>
                      <li>T45 / 76mm Retrac DC</li>
                      <li>T45 / 89 mm Retrac DC</li>
                  </ul>
              </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                  <h3>Referanser</h3>
              </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                  <p>«Bedriften Rock Partner AS ved Rune Ingebrigtsen har drevet utstrakt testing av disse kronene i
                      Fana Stein AS med til dels svært utfordrende bergarter. Ettersom resultatene har vært gode, har vi
                      blitt totalleverandør av borekroner hos dem»</p>
              </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                  <p>«Vassbakk &amp; Stol AS har på prosjektet Flesland Terminal brukt våre 70mm LA-kroner med godt
                      resultat. Dette viser hvor god kvalitet det er på disse kronene.»</p>
              </div>
          </div>
      </div>
    </div>
    <div className="col-sm-3">
      <Contact />
    </div>
  </div>
);

export default Borekroner;
