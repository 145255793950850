import React from 'react';
import {Nav, Navbar, NavDropdown, NavItem, MenuItem} from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import {Link, Route} from 'react-router-dom';
import logo from './assets/logo.png';

const Header = () => (
    <Navbar fluid bsStyle="none" collapseOnSelect>
        <Navbar.Header>
            <Navbar.Brand>
                <a href="/">
                    <img src={logo} alt="LA Consulting Logo" width="250" />
                </a>
            </Navbar.Brand>
            <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse id="main-navbar-collapse">
            <Nav id="menu-toppmeny" className="navbar-right" activeHref={window.location.pathname}>
                <LinkContainer to="/" exact>
                    <NavItem>Hjem</NavItem>
                </LinkContainer>
                <LinkContainer to="/om-oss/">
                    <NavItem>Om oss</NavItem>
                </LinkContainer>
                <NavDropdown  title="Produkter" id="basic-nav-dropdown">
                    <LinkContainer to="/produkter/boreutstyr/">
                        <MenuItem>Boreutstyr</MenuItem>
                    </LinkContainer>
                    <LinkContainer to="/produkter/skytematter/">
                        <MenuItem>Skytematter</MenuItem>
                    </LinkContainer>
                    <LinkContainer to="/produkter/borekroner/">
                        <MenuItem>Borekroner</MenuItem>
                    </LinkContainer>
                </NavDropdown>
                <LinkContainer to="/galleri/">
                    <NavItem>Galleri</NavItem>
                </LinkContainer>
                <LinkContainer to="/kontakt-oss/">
                    <NavItem>Kontakt oss</NavItem>
                </LinkContainer>
            </Nav>
        </Navbar.Collapse>
        <Route path="/" exact>
            <div className="masthead">
                <h1>LA CONSULTING AS</h1>
                <p>LA Consulting AS leverer borekroner, skytematter og øvrig boreutstyr til
                    anleggsbransjen i Hordaland og
                    nærliggende fylker. LA Consulting AS har fokus på å være den leverandøren i markedet som er best på service og
                    kunnskap. Ta gjerne kontakt for tilbud eller en uforpliktende og hyggelig prat.</p>
                <div>
                    <Link to="/produkter/">
                        <span>PRODUKTER</span>
                    </Link>
                    <Link to="/kontakt-oss/">
                        <span>KONTAKT OSS</span>
                    </Link>
                </div>
            </div>
        </Route>
    </Navbar>
);

export default Header;