import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import Header from './Header';
import './App.css';
import Hjem from "./pages/Hjem";
import OmOss from "./pages/OmOss";
import Produkter from "./pages/Produkter";
import Boreutstyr from "./pages/Boreutstyr";
import Borekroner from "./pages/Borekroner";
import Skytematter from "./pages/Skytematter";
import Galleri from "./pages/Galleri";
import KontaktOss from "./pages/KontaktOss";

const App = () => {
    return (
        <div className="App">
            <Router>
                <Header />
                <div className="container-fluid">
                    <Switch>
                        <Route path="/om-oss/">
                            <OmOss />
                        </Route>
                        <Route path="/produkter/boreutstyr/">
                            <Boreutstyr />
                        </Route>
                        <Route path="/produkter/skytematter/">
                            <Skytematter />
                        </Route>
                        <Route path="/produkter/borekroner/">
                            <Borekroner />
                        </Route>
                        <Route path="/produkter/">
                            <Produkter />
                        </Route>
                        <Route path="/galleri/">
                            <Galleri/>
                        </Route>
                        <Route path="/kontakt-oss/">
                            <KontaktOss />
                        </Route>
                        <Route path="/">
                            <Hjem />
                        </Route>
                    </Switch>
                </div>
            </Router>
            <div className="frontpage-subboxes">
                <div>
                    <h3>LA CONSULTING</h3>
                    <p>
                        Vi leverer borekroner, skytematter og øvrig boreutstyr til anleggsbransjen i Hordaland og
                        nærliggende fylker.
                    </p>
                </div>
                <div>
                    <h3>VÅRT MÅL</h3>
                    <p>
                        Vårt mål er å levere best utstyr til best pris, samt å hevde oss på service og kunnskap om
                        produktene vi selger.
                    </p>
                </div>
                <div>
                    <h3>TJENESTER</h3>
                    <p>LA Consulting AS leverer borekroner, øvrig boreutstyr og skytematter.</p>
                </div>
                <div>
                    <h3>KONTAKTINFORMASJON</h3>
                    <p>Adresse: Postboks 3,<br/>
                        5318 Strusshamn<br/>
                        Telefon: 41 25 77 45<br/>
                        E-post: leiflu07@gmail.com
                    </p>
                </div>
            </div>
        </div>
    );
};

export default App;
