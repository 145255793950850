import React, {useEffect} from 'react';
import Contact from "../Contact";

const fbScript = `
(function(d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) return;
                  js = d.createElement(s); js.id = id;
                  js.src = '//connect.facebook.net/nb_NO/sdk.js#xfbml=1&version=v2.8&appId=1107680435995218';
             fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));`;

const Galleri = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.innerHTML = fbScript;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className="row content-row page-content">
            <div className="col-sm-9">
                <div className="blog-post">
                    <h1 className="blog-post-title">Galleri</h1>
                    <p>Vassbakk &amp; Stol AS bruker skytematter fra LA Consulting. Her ser du dem i bruk ved Bergen Lufthavn
                        Flesland:</p>
                    <div id="fb-root"></div>
                    <div className="fb-video" style={{ marginBottom: 20 }}
                         data-href="https://web.facebook.com/ottesenso/videos/10151895134611124/?autoplay_reason=gatekeeper&amp;video_container_type=0&amp;video_creator_product_type=0&amp;app_id=350685531728&amp;live_video_guests=0"
                         data-width="500" data-show-text="false">
                    </div>
                    <h2>Bilder fra assortement</h2>
                    <div id='gallery-1' className='gallery galleryid-4 gallery-columns-5 gallery-size-thumbnail'>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon landscape'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082742/Trafikkbuffer-1024x768.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082742/Trafikkbuffer-1024x768-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon landscape'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082740/ref2.png'><img
                                    width="136" height="123"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082740/ref2.png"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082740/ref3.png'><img
                                    width="136" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082740/ref3-136x150.png"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon landscape'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082741/Trafikkbuffer-2-1024x768.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082741/Trafikkbuffer-2-1024x768-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082739/ref1.png'><img
                                    width="136" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082739/ref1-136x150.png"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <br style={{ clear: 'both' }} />
                        <dl className='gallery-item'>
                            <dt className='gallery-icon landscape'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082738/Mitsubishi-1.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082738/Mitsubishi-1-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082738/Kronekoffert-e1432640587368-768x1024.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082738/Kronekoffert-e1432640587368-768x1024-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082736/Bornakke-e1432640610355.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082736/Bornakke-e1432640610355-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon landscape'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082736/Kasse-for-borkroner-1024x768.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082736/Kasse-for-borkroner-1024x768-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082735/Bornakke-3.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082735/Bornakke-3-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <br style={{ clear: 'both' }} />
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082735/Bornakke-4-e1432640621599.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082735/Bornakke-4-e1432640621599-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082734/Bornakke-2-e1432640634610.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082734/Bornakke-2-e1432640634610-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082733/Borkroner-4-e1432640659609.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082733/Borkroner-4-e1432640659609-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082734/Borkroner-e1432640647226-768x1024.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082734/Borkroner-e1432640647226-768x1024-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082732/Borkroner-2-e1432640683251.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082732/Borkroner-2-e1432640683251-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <br style={{ clear: 'both' }} />
                        <dl className='gallery-item'>
                            <dt className='gallery-icon portrait'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082732/Borkroner-3-e1432640671408.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082732/Borkroner-3-e1432640671408-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <dl className='gallery-item'>
                            <dt className='gallery-icon landscape'>
                                <a href='http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082731/Borkrone-i-kasse-2-1024x768.jpg'><img
                                    width="150" height="150"
                                    src="http://s3-eu-west-1.amazonaws.com/laconsulting-site/wp-content/uploads/2017/06/07082731/Borkrone-i-kasse-2-1024x768-150x150.jpg"
                                    className="attachment-thumbnail size-thumbnail" alt=""/></a>
                            </dt>
                        </dl>
                        <br style={{ clear: 'both' }} />
                    </div>
                </div>
            </div>
            <div className="col-sm-3">
                <Contact />
            </div>
        </div>
    );
};

export default Galleri;
