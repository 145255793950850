import React from 'react';
import Contact from "../Contact";
import {Link} from "react-router-dom";

const Produkter = () => (
  <div className="row content-row page-content">
    <div className="col-sm-9">
      <div className="blog-post">
          <h1 className="blog-post-title">Produkter</h1>
          <p>LA Consult AS leverer borekroner, øvrig boreutstyr og skytematter.</p>
          <ul>
              <li><Link to="/produkter/boreutstyr/">Boreutstyr</Link></li>
              <li><Link to="/produkter/skytematter/">Skytematter</Link></li>
              <li><Link to="/produkter/borekroner/">Borekroner</Link></li>
          </ul>
      </div>
    </div>
    <div className="col-sm-3">
      <Contact />
    </div>
  </div>
);

export default Produkter;
