import React from 'react';
import Contact from "../Contact";
import {Link} from "react-router-dom";

const Boreutstyr = () => (
  <div className="row content-row page-content">
    <div className="col-sm-9">
      <div className="blog-post">
          <h1 className="blog-post-title">Boreutstyr</h1>
          <h2>Borehullsplugger – koniske</h2>
          <table className="table">
              <thead>
              <tr>
                  <th>Serie</th>
                  <th>For diameter</th>
                  <th>Ant. pr. eske</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>11</td>
                  <td>30-40 mm</td>
                  <td>450 stk.</td>
              </tr>
              <tr>
                  <td>12</td>
                  <td>40-50 mm</td>
                  <td>250 stk.</td>
              </tr>
              <tr>
                  <td>13</td>
                  <td>50-70 mm</td>
                  <td>100 stk.</td>
              </tr>
              <tr>
                  <td>14</td>
                  <td>75-120 mm</td>
                  <td>176 stk.</td>
              </tr>
              </tbody>
          </table>
          <h2>Borehullsplugger – traktformede</h2>
          <table className="table">
              <thead>
              <tr>
                  <th>Serie</th>
                  <th>For diameter</th>
                  <th>Ant. pr. eske</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>51</td>
                  <td>51 mm / 2″</td>
                  <td>96 stk.</td>
              </tr>
              <tr>
                  <td>64</td>
                  <td>64 mm / 2,5″</td>
                  <td>52 stk.</td>
              </tr>
              <tr>
                  <td>76</td>
                  <td>76 mm / 3″</td>
                  <td>80 stk.</td>
              </tr>
              </tbody>
          </table>
          <h2>Håndholdt slipemaskin for stiftborkroner – W22</h2>
          <table className="table">
              <tbody>
              <tr>
                  <td>Spindelrotasjon</td>
                  <td>22 000 o/min</td>
              </tr>
              <tr>
                  <td>Vekt</td>
                  <td>1,4 kg</td>
              </tr>
              <tr>
                  <td>Luftforbruk</td>
                  <td>14,5 l/sek</td>
              </tr>
              <tr>
                  <td>Arbeidstrykk</td>
                  <td>6-8 bar</td>
              </tr>
              <tr>
                  <td>Smørebehov</td>
                  <td>8-12 dråper/min</td>
              </tr>
              <tr>
                  <td>Luftslange med klokopling</td>
                  <td>2,5 m inkludert</td>
              </tr>
              <tr>
                  <td>Hurtigchuck for slipekopp</td>
                  <td>Wing-drive, 8 mm tange</td>
              </tr>
              </tbody>
          </table>
          <h2>Slipekopper HD-type</h2>
          <p>Leveres i stiftdiameter fra 7 mm til 19 mm.</p>
          <h2>Rettsliper – WR45</h2>
          <p>Rettsliper for 150 mm diameter, rett slipeskive.</p>
          <table className="table">
              <tbody>
              <tr>
                  <td>Omdreininger</td>
                  <td>4500 o/min</td>
              </tr>
              <tr>
                  <td>Luftforbruk</td>
                  <td>28 l/s</td>
              </tr>
              <tr>
                  <td>Lufttrykk</td>
                  <td>5-6 bar</td>
              </tr>
              <tr>
                  <td>Luftslange min. Ø</td>
                  <td>16 mm</td>
              </tr>
              <tr>
                  <td>Maskinvekt netto</td>
                  <td>4,5 kg</td>
              </tr>
              <tr>
                  <td>Slipeskive Ø, maks</td>
                  <td>150 mm</td>
              </tr>
              </tbody>
          </table>
          <h2>Batteridrevet pulserende sirene – WEP117</h2>
          <p>Sirene med innebygget batteri som driver luftkompressor for sirene – kan lades fra bilens sigarettenner.
              Trenger ingen eksterne tilkoblinger når sirenen skal brukes.</p>
          <p>Lydnivå: 117 dB</p>
          <p>Vekt: 8,5 kg</p>
          <h2>Luftdrevet pulserende sirene – TP75</h2>
          <p>WIPCO har nå på lager TP75 automatisk pulserende sirene for montering på riggens luftuttak.</p>
          <p>Lydnivå: 130 dB</p>
      </div>
    </div>
    <div className="col-sm-3">
      <Contact />
    </div>
  </div>
);

export default Boreutstyr;
