import React from 'react';
import Contact from "../Contact";
import leif from '../assets/Leif.jpg';
import gunhild from '../assets/Gunnhild.jpg';
import jorgen from '../assets/Jørgen.jpg';

const KontaktOss = () => (
    <div className="row content-row page-content">
        <div className="col-sm-9">
            <h1>Kontakt</h1>
            <p>Har du spørsmål angående noen av våre produkter eller tjenester? Benytt kontaktsjema eller ring oss for en
                hyggelig og uforpliktende prat.</p>
            <p><strong>Postadresse:</strong> Postboks 3, 5318 Strusshamn</p>
            <p><strong>Besøksadresse:</strong> Hjortlandsveien 230, 5135 Flaktveit</p>
            <p><strong>E-post:</strong> leiflu07@gmail.com</p>
            <hr />
            <div className="employees">
                <div>
                    <img src={leif} width="150"/>
                    <p>
                        <strong>Leif</strong><br/>
                        <em>Daglig leder</em>
                    </p>
                    <p>
                        <strong>Telefon:</strong> 41 25 77 45
                    </p>
                </div>
                <div>
                    <img src={jorgen} width="150"/>
                    <p>
                        <strong>Jørgen</strong><br/>
                        <em>Lagermedarbeider og sjåfør</em>
                    </p>
                    <p>
                        <strong>Telefon:</strong> 95 12 54 76
                    </p>
                </div>
            </div>
            <hr />
            <iframe style={{ width: '100%', height: 300 }} frameBorder="0" src="//www.google.com/maps/embed/v1/place?q=La+consulting+AS,Norway
      &zoom=10
      &key=AIzaSyBstIp0Dhql85rGMguMREcaGI1vlaibkdY">
            </iframe>
        </div>
        <div className="col-sm-3">
            <Contact />
        </div>
    </div>
);

export default KontaktOss;
