import React from 'react';
import Contact from "../Contact";
import {Link} from "react-router-dom";

const Skytematter = () => (
  <div className="row content-row page-content">
    <div className="col-sm-9">
      <div className="blog-post">
          <h1 className="blog-post-title">Skytematter</h1>
          <p>Vi er stolte forhandlere av Drammensmatta, en kvalitetsmatte som er produsert i Norge, for norske
              forhold.</p>
          <p>Vår største kunde Vassbakk &amp; Stol AS, en av de største og mest seriøse entreprenørbedriftene på
              vestlandet – de er vår hovedreferanse på skytematter. Vassbakk &amp; Stol AS har investert i over 500
              skytematter det siste året.</p>
          <p>Fordelen med Drammensmatta, er at den er sydd med dekkbaner av gjennomgående høy kvalitet, noe som igjen
              gir mattene bedre levetid. Mattene kan også leveres med ekstra forsterkning, og leverandøren er i gang med
              utviklingen av en helt ny matte hvor denne forsterkningen ligger på innsiden. Med dette produktet vil man
              kunne ha forsterkning, samtidig som man beholder brukervennligheten til standardmatter (enkle å håndtere
              uten å kutte tennere og koblinger).</p>
          <p>Vi finner transportløsninger for hele landet, fra nærmeste lager.</p>
          <p><em>«Med 30 års erfaring fra anleggsbransjen sitter jeg igjen med utelukkende positive erfaringer med dette
              produktet. I dagens samfunn kreves det kvalitet for å sikre en trygg jobbutførelse og imøtekomme gjeldende
              regelverk.»</em></p>
      </div>
    </div>
    <div className="col-sm-3">
      <Contact />
    </div>
  </div>
);

export default Skytematter;
