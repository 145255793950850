import React from 'react';

const Contact = () => (
  <div className="blog-sidebar">
    <div style={{ padding: 10, backgroundColor: '#1b2a2f' }}>
      <h3 style={{ marginTop: 10, color: 'white' }}>Har du spørsmål angående noen av våre produkter eller tjenester?</h3>
      <div role="form" className="wpcf7" id="wpcf7-f29-o1" lang="nb-NO" dir="ltr">
        <p>E-post:</p>
        <p><a href="mailto:leiflu07@gmail.com">leiflu07@gmail.com</a></p>
        <p>Telefon:</p>
        <p><a href="tel:41257745">41257745</a></p>
        <div className="screen-reader-response"></div>

      </div>
  </div>
    </div>
);

export default Contact;
